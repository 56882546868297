import { ReactNode, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Grid, InputBaseComponentProps, makeStyles, SvgIcon } from '@material-ui/core';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { FormSelectField, FormTextField } from 'common/components';

import { country } from 'config';
import { Alert } from '@material-ui/lab';
import {
  Address,
  DeliveryDetailsFormData,
  deliveryDetailsSchemaFormMaxFieldsLength,
  PackageLeavingOption,
  VerifiedAddress
} from '../types';
import {
  OTHER_PACKAGE_LEAVING_OPTION,
  PACKAGE_LEAVING_OPTION_UNDER_THE_DOOR
} from '../constants/packageLeavingOptions';
import { DictionaryTag } from '../../../common/types/dictionaryTag';
import { FormRadioGroupField } from '../../../common/components/Form/FormRadioGroupField';
import { UNDER_THE_DOOR_TYPE_CODE } from '../constants/underTheDoorTypes';
import { AddressDeliveryTime } from '../api/verifyAddress/types';
import { getPackageLeavingConfig } from '../utils/getPackageLeavingConfig';
import { ReactComponent as IconDay } from 'common/icons/icon-day.svg';
import { ReactComponent as IconNight } from 'common/icons/icon-night.svg';
import { ReactComponent as IconDelivery } from 'common/icons/icon-delivery.svg';
import { formatAddress } from 'features/addresses';
import { ClassNameMap } from '@material-ui/styles';
import { DELIVERY_TYPE_EVENING, DELIVERY_TYPE_STANDARD } from '../constants/deliveryTypes';

const getPackageLeavingOptionLabel = (o: PackageLeavingOption) => o.value;
const getPackageLeavingOptionValue = (o: PackageLeavingOption) => o.id.toString();

const nameInputProps: InputBaseComponentProps = {
  maxLength: deliveryDetailsSchemaFormMaxFieldsLength.name
};

const floorNrInputProps: InputBaseComponentProps = {
  maxLength: deliveryDetailsSchemaFormMaxFieldsLength.floorNr,
  inputMode: 'numeric'
};

const gateKeyInputProps: InputBaseComponentProps = {
  maxLength: deliveryDetailsSchemaFormMaxFieldsLength.gateKey
};

const companyNameInputProps: InputBaseComponentProps = {
  maxLength: deliveryDetailsSchemaFormMaxFieldsLength.companyName
};

const intercomNameInputProps: InputBaseComponentProps = {
  maxLength: deliveryDetailsSchemaFormMaxFieldsLength.intercomName
};

const otherPackageLeavingOptionInputProps: InputBaseComponentProps = {
  maxLength: deliveryDetailsSchemaFormMaxFieldsLength.otherPackageLeavingOption
};

const intercomKeyInputProps: InputBaseComponentProps = {
  maxLength: deliveryDetailsSchemaFormMaxFieldsLength.intercomKey
};

const availableDeliveryHoursToOptions = (
  availableDeliveryHours: AddressDeliveryTime[],
  t: TFunction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classes: ClassNameMap<any>,
  type: 'default' | 'standard' | 'weekend'
): {
  value: AddressDeliveryTime;
  label: ReactNode;
}[] => {
  const results: {
    value: AddressDeliveryTime;
    label: ReactNode;
  }[] = [];

  availableDeliveryHours.forEach((delivery) => {
    let icon;
    let append;
    if (country === 'czech' && delivery.deliveryTypeKey === DELIVERY_TYPE_STANDARD) {
      icon = <SvgIcon component={IconDay} className={classes.deliveryHoursIcon} />;
      append = <Trans i18nKey={'addresses.deliveryDetails.deliveryHoursHint.STANDARD'} />;
    } else if (country === 'czech' && delivery.deliveryTypeKey === DELIVERY_TYPE_EVENING) {
      icon = <SvgIcon component={IconNight} className={classes.deliveryHoursIcon} />;
      append = <Trans i18nKey={'addresses.deliveryDetails.deliveryHoursHint.EVENING'} />;
    }

    if (type === 'default') {
      results.push({
        value: delivery,
        label: (
          <>
            {icon}
            <Trans
              i18nKey={
                delivery.timeFrom
                  ? 'addresses.deliveryDetails.deliveryTo.optionLabelSinceTo'
                  : 'addresses.deliveryDetails.deliveryTo.optionLabelTo'
              }
              values={{
                timeTo: delivery.timeTo,
                timeFrom: delivery.timeFrom
              }}
            />
            {append}
          </>
        )
      });
    } else if (type === 'standard' && !delivery.isWeekend) {
      results.push({
        value: delivery,
        label: (
          <>
            {icon}
            <Trans
              i18nKey={
                delivery.timeFrom
                  ? 'addresses.deliveryDetails.deliveryHourTo.optionLabelSinceTo'
                  : 'addresses.deliveryDetails.deliveryHourTo.optionLabelTo'
              }
              values={{
                timeTo: delivery.timeTo,
                timeFrom: delivery.timeFrom
              }}
            />
            {append}
          </>
        )
      });
    } else if (type === 'weekend' && delivery.isWeekend) {
      results.push({
        value: delivery,
        label: (
          <>
            {icon}
            <Trans
              i18nKey={
                delivery.timeFrom
                  ? 'addresses.deliveryDetails.deliveryWeekendHourTo.optionLabelSinceTo'
                  : 'addresses.deliveryDetails.deliveryWeekendHourTo.optionLabelTo'
              }
              values={{
                timeTo: delivery.timeTo,
                timeFrom: delivery.timeFrom
              }}
            />
            {append}
          </>
        )
      });
    }
  });

  return results;
};

const availableWeekendDeliveryHoursToOptions = (availableDeliveryHours: AddressDeliveryTime[], t: TFunction) =>
  availableDeliveryHours.map((delivery) => ({
    value: delivery,
    label: delivery.timeFrom
      ? t('addresses.deliveryDetails.deliveryHourTo.optionLabelSinceTo', {
          timeTo: delivery.timeTo,
          timeFrom: delivery.timeFrom
        })
      : t('addresses.deliveryDetails.deliveryHourTo.optionLabelTo', { timeTo: delivery.timeTo })
  }));

export interface PartialDeliveryDetailsFormProps {
  packageLeavingOptions: PackageLeavingOption[];
  underTheDoorTypes: DictionaryTag[];
  availableDeliveryHours: AddressDeliveryTime[];
  className?: string;
  address: Address | VerifiedAddress | null;
}

export const PartialDeliveryDetailsForm = ({
  packageLeavingOptions,
  underTheDoorTypes,
  availableDeliveryHours,
  className,
  address
}: PartialDeliveryDetailsFormProps) => {
  const { setValue, clearErrors, getValues } = useFormContext<DeliveryDetailsFormData>();
  const { t } = useTranslation();
  const classes = useStyle();

  const packageLeavingOption = useWatch<DeliveryDetailsFormData, 'packageLeavingOption'>({
    name: 'packageLeavingOption'
  });

  const handleChangeDeliverTime = (value: AddressDeliveryTime) => {
    setValue('deliveryHourFrom', value.timeFrom || null);
    setValue('deliveryHourTo', value.timeTo);
    setValue('deliveryType', value.deliveryTypeId);
    return value.timeTo;
  };

  const handleChangeWeekendDeliverTime = (value: AddressDeliveryTime) => {
    setValue('deliveryWeekendHourFrom', value.timeFrom || null);
    setValue('deliveryWeekendHourTo', value.timeTo);
    setValue('deliveryWeekendType', value.deliveryTypeId);
    return value.timeTo;
  };

  const underTheDoorType = useWatch<DeliveryDetailsFormData, 'underTheDoorType'>({
    name: 'underTheDoorType'
  });

  const [intercomRequired, setIntercomRequired] = useState(false);
  const [otherPackageLeavingOptionRequired, setOtherPackageLeavingOptionRequired] = useState(false);

  useEffect(() => {
    const config = getPackageLeavingConfig(packageLeavingOption, underTheDoorType, country);
    setOtherPackageLeavingOptionRequired(config.otherPackageLeavingOptionRequired);
    setIntercomRequired(config.intercomRequired);

    if (packageLeavingOption !== PACKAGE_LEAVING_OPTION_UNDER_THE_DOOR) {
      setValue('underTheDoorType', null);
    }

    clearErrors(['otherPackageLeavingOption', 'intercomKey', 'intercomName']);
  }, [
    packageLeavingOption,
    underTheDoorType,
    setValue,
    clearErrors,
    setIntercomRequired,
    setOtherPackageLeavingOptionRequired
  ]);

  const deliveryHourOptions = availableDeliveryHoursToOptions(availableDeliveryHours, t, classes, 'standard');
  const [defaultValue] = availableDeliveryHoursToOptions(
    [
      {
        timeFrom: getValues().deliveryHourFrom || undefined,
        timeTo: getValues().deliveryHourTo as string,
        deliveryTypeKey: getValues().deliveryTypeKey as string,
        deliveryTypeId: getValues().deliveryType as number,
        isWeekend: false
      }
    ],
    t,
    classes,
    'default'
  );

  const deliveryWeekendHourOptions = availableDeliveryHoursToOptions(availableDeliveryHours, t, classes, 'weekend');
  const [defaultWeekendValue] = availableDeliveryHoursToOptions(
    [
      {
        timeFrom: getValues().deliveryWeekendHourFrom || undefined,
        timeTo: getValues().deliveryWeekendHourTo as string,
        deliveryTypeKey: getValues().deliveryWeekendTypeKey as string,
        deliveryTypeId: getValues().deliveryWeekendType as number,
        isWeekend: true
      }
    ],
    t,
    classes,
    'default'
  );

  return (
    <Grid container spacing={4} className={className}>
      <Grid item xs={12} sm={12}>
        <div className={classes.address}>
          <SvgIcon component={IconDelivery} />
          {address && formatAddress(address, 'parenthesis')}
        </div>
      </Grid>
      <FormTextField className={classes.hide} name="deliveryType" />
      <FormTextField className={classes.hide} name="deliveryHourFrom" />
      {country !== 'czech' && (
        <Grid item xs={12} sm={6}>
          <FormSelectField
            name="deliveryHourTo"
            label={t('addresses.deliveryDetails.deliveryTo.label')}
            options={deliveryHourOptions}
            defaultValue={defaultValue}
            placeholder={t('addresses.deliveryDetails.deliveryTo.placeholder')}
            onSelect={handleChangeDeliverTime}
            getValueFromOption={(option) => option.value}
          />
        </Grid>
      )}
      {country === 'czech' && (
        <>
          <Grid>
            <p className={classes.deliveryHoursHint}>
              {t('addresses.deliveryDetails.deliveryHoursHint.part1')}
              <SvgIcon component={IconNight} className={classes.deliveryHoursIcon} />
              {t('addresses.deliveryDetails.deliveryHoursHint.part2')}
              <SvgIcon component={IconDay} className={classes.deliveryHoursIcon} />
              {t('addresses.deliveryDetails.deliveryHoursHint.part3')}
              <SvgIcon component={IconDay} className={classes.deliveryHoursIcon} />
            </p>
          </Grid>
          <FormTextField className={classes.hide} name="deliveryWeekendType" />
          <FormTextField className={classes.hide} name="deliveryWeekendHourFrom" />
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name="deliveryHourTo"
              label={t('addresses.deliveryDetails.deliveryHourTo.label')}
              options={deliveryHourOptions}
              defaultValue={defaultValue}
              placeholder={t('addresses.deliveryDetails.deliveryHourTo.placeholder')}
              onSelect={handleChangeDeliverTime}
              getValueFromOption={(option) => option.value}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name="deliveryWeekendHourTo"
              label={t('addresses.deliveryDetails.deliveryWeekendHourTo.label')}
              options={deliveryWeekendHourOptions}
              defaultValue={defaultWeekendValue}
              placeholder={t('addresses.deliveryDetails.deliveryWeekendHourTo.placeholder')}
              onSelect={handleChangeWeekendDeliverTime}
              getValueFromOption={(option) => option.value}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="name"
          label={t('addresses.deliveryDetails.name.label')}
          placeholder={t('addresses.deliveryDetails.name.placeholder')}
          inputProps={nameInputProps}
        />
      </Grid>
      {country === 'germany' && (
        <>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="intercomName"
              label={t(
                intercomRequired
                  ? 'addresses.deliveryDetails.intercomName.label'
                  : 'addresses.deliveryDetails.intercomName.optional_label'
              )}
              placeholder={t('addresses.deliveryDetails.intercomName.placeholder')}
              inputProps={intercomNameInputProps}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="companyName"
              label={t('addresses.deliveryDetails.companyName.label')}
              placeholder={t('addresses.deliveryDetails.companyName.placeholder')}
              inputProps={companyNameInputProps}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12}>
        <FormSelectField
          name="packageLeavingOption"
          label={t('addresses.deliveryDetails.packageLeavingOption.label')}
          options={packageLeavingOptions}
          getOptionLabel={getPackageLeavingOptionLabel}
          getOptionValue={getPackageLeavingOptionValue}
          placeholder={t('addresses.deliveryDetails.packageLeavingOption.placeholder')}
          getValueFromOption={(option: PackageLeavingOption) => option.id}
        />
      </Grid>
      {packageLeavingOption <= 0 && (
        <Grid item xs={12} sm={12}>
          <Alert className={classes.alert} variant="outlined" severity="info">
            {t('addresses.deliveryDetails.packageLeavingOption.empty')}
          </Alert>
        </Grid>
      )}
      {!!(packageLeavingOption > 0) && (
        <>
          <Grid item xs={12} sm={12}>
            <h3>
              {t(
                packageLeavingOption === OTHER_PACKAGE_LEAVING_OPTION
                  ? 'addresses.deliveryDetails.packageLeavingSection.other_header'
                  : 'addresses.deliveryDetails.packageLeavingSection.header'
              )}
            </h3>
            <p className={classes.packageLeavingDescription}>
              {t('addresses.deliveryDetails.packageLeavingSection.description')}
            </p>
            {!!(packageLeavingOption === PACKAGE_LEAVING_OPTION_UNDER_THE_DOOR && underTheDoorTypes.length) && (
              <FormRadioGroupField<DictionaryTag>
                name="underTheDoorType"
                defaultValue={underTheDoorTypes[0].systemValue as string}
                getOptionAlt={(option: DictionaryTag) => option.value}
                getOptionLabel={(option: DictionaryTag) => (
                  <>
                    {option.value}
                    {option.systemValue === UNDER_THE_DOOR_TYPE_CODE && (
                      <span className={classes.underTheDoorTypeCodeLabel}>
                        {t('addresses.deliveryDetails.underTheDoorType.code.label')}
                      </span>
                    )}
                  </>
                )}
                getOptionValue={(option: DictionaryTag) => option.systemValue as string}
                options={underTheDoorTypes}
              />
            )}
          </Grid>
          {otherPackageLeavingOptionRequired && (
            <Grid item xs={12} sm={12}>
              <FormTextField
                label={t('addresses.deliveryDetails.otherPackageLeavingOption.label')}
                className={classes.otherPackageLeavingOption}
                name="otherPackageLeavingOption"
                multiline
                rows={4}
                placeholder={t('addresses.deliveryDetails.otherPackageLeavingOption.placeholder')}
                inputProps={otherPackageLeavingOptionInputProps}
              />
            </Grid>
          )}
          {country === 'czech' && (
            <>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="intercomName"
                  label={t(
                    intercomRequired
                      ? 'addresses.deliveryDetails.intercomName.label'
                      : 'addresses.deliveryDetails.intercomName.optional_label'
                  )}
                  placeholder={t('addresses.deliveryDetails.intercomName.placeholder')}
                  inputProps={intercomNameInputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="intercomKey"
                  label={t(
                    intercomRequired
                      ? 'addresses.deliveryDetails.intercomKey.label'
                      : 'addresses.deliveryDetails.intercomKey.optional_label'
                  )}
                  placeholder={t('addresses.deliveryDetails.intercomKey.placeholder')}
                  inputProps={intercomKeyInputProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="companyName"
                  label={t('addresses.deliveryDetails.companyName.label')}
                  placeholder={t('addresses.deliveryDetails.companyName.placeholder')}
                  inputProps={companyNameInputProps}
                />
              </Grid>
            </>
          )}
          {country === 'poland' && (
            <>
              <Grid item xs={12} sm={4}>
                <FormTextField
                  name="intercomKey"
                  label={t(
                    intercomRequired
                      ? 'addresses.deliveryDetails.intercomKey.label'
                      : 'addresses.deliveryDetails.intercomKey.optional_label'
                  )}
                  placeholder={t('addresses.deliveryDetails.intercomKey.placeholder')}
                  inputProps={intercomKeyInputProps}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormTextField
                  name="gateKey"
                  label={t('addresses.deliveryDetails.gateKey.label')}
                  placeholder={t('addresses.deliveryDetails.gateKey.placeholder')}
                  inputProps={gateKeyInputProps}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={country === 'poland' ? 4 : 6}>
            <FormTextField
              name="floorNr"
              label={t('addresses.deliveryDetails.floorNr.label')}
              placeholder={t('addresses.deliveryDetails.floorNr.placeholder')}
              inputProps={floorNrInputProps}
            />
          </Grid>
          {!otherPackageLeavingOptionRequired && (
            <Grid item xs={12} sm={12}>
              <FormTextField
                label={t('addresses.deliveryDetails.otherPackageLeavingOption.optional_label')}
                className={classes.otherPackageLeavingOption}
                name="otherPackageLeavingOption"
                multiline
                rows={4}
                placeholder={t('addresses.deliveryDetails.otherPackageLeavingOption.placeholder')}
                inputProps={otherPackageLeavingOptionInputProps}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const useStyle = makeStyles((theme) => ({
  address: {
    backgroundColor: '#FFE123',
    borderRadius: '24px',
    padding: '8px',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px'
  },
  otherPackageLeavingOption: {
    marginTop: theme.spacing(1)
  },
  alert: {
    border: `1px solid ${theme.customColors.whisperGrey}`,
    justifyContent: 'center',
    '& > .MuiAlert-icon': {
      color: theme.customColors.black
    }
  },
  packageLeavingDescription: {
    color: theme.customColors.dimGrey
  },
  deliveryHoursHint: {
    color: theme.customColors.dimGrey,
    padding: '0 1rem'
  },
  deliveryHoursIcon: {
    marginBottom: '-8px',
    marginLeft: '4px'
  },
  underTheDoorTypeCodeLabel: {
    backgroundColor: theme.customColors.white,
    borderRadius: '100px',
    padding: '4px 8px',
    marginLeft: '1em'
  },
  hide: {
    display: 'none'
  }
}));
