import { useController, useFormContext } from 'react-hook-form';
import { SingleValue } from 'react-select';
import { FormErrorMessage } from '../FormErrorMessage';
import { SelectField, SelectFieldProps } from '../SelectField';

interface FormSelectFieldProps<OptionType> extends SelectFieldProps<OptionType> {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  getValueFromOption: (option: OptionType) => unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (value?: any) => void;
  options: OptionType[];
}

export const FormSelectField = <OptionType,>({
  name,
  defaultValue,
  getValueFromOption,
  options,
  onSelect = undefined,
  ...rest
}: FormSelectFieldProps<OptionType>): JSX.Element => {
  const {
    formState: { errors }
  } = useFormContext();
  const {
    field: { onChange, value, ...restField },
    fieldState: { error }
  } = useController({
    name,
    defaultValue
  });

  const handleChange = (newValue: SingleValue<OptionType>) => {
    if (newValue !== null) {
      onChange(getValueFromOption(newValue));

      if (onSelect) {
        onSelect((newValue as { value: OptionType })?.value);
      }
    }
  };

  return (
    <SelectField
      {...rest}
      {...restField}
      defaultValue={defaultValue}
      onChange={handleChange}
      error={!!error}
      value={options.find((o) => getValueFromOption(o) === value)}
      options={options}
      helperText={<FormErrorMessage errors={errors} name={name} />}
    />
  );
};
