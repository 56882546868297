import { makeStyles, SvgIcon, Tooltip } from '@material-ui/core';
import { FunctionComponent, ReactNode, SVGProps } from 'react';

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface InfoTooltipArrowProps {
  name: string;
  title: NonNullable<ReactNode>;
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  placement?: Placement;
  className?: string;
}

export const InfoTooltipArrow = ({ name, title, icon, className, placement = 'bottom' }: InfoTooltipArrowProps) => {
  const classes = useStyle();

  return (
    <Tooltip
      className={className}
      key={name}
      title={title}
      placement={placement}
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
    >
      <div className={classes.iconWrapper}>
        <SvgIcon component={icon} classes={{ root: classes.icon }} />
      </div>
    </Tooltip>
  );
};

const useStyle = makeStyles((theme) => ({
  icon: {
    fontSize: '25px',
    overflow: 'visible',
    position: 'absolute',
    top: '0',
    transform: 'translateY(-75%)',
    cursor: 'help'
  },
  iconWrapper: {
    position: 'relative',
    display: 'inline-block',
    width: '25px'
  },
  tooltip: {
    padding: '1rem',
    background: theme.customColors.black,
    color: theme.customColors.white,
    fontSize: '0.875rem',
    maxWidth: 'none',
    fontWeight: 'normal',
    textAlign: 'center'
  },
  arrow: {
    color: theme.customColors.black
  }
}));
