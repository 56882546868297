export const clearPhoneNumberCharacters = [' ', '(', ')', '-'];

export function clearString(value?: string, clearCharacters: string[] = []): string | undefined {
  if (value) {
    clearCharacters.forEach((character) => {
      value = value?.replace(character, '');
    });
  }

  return value;
}
