import { Grid, InputBaseComponentProps, InputProps, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormTextField } from 'common/components';
import { useMobileScreenSizeDetector } from 'common/hooks';

import { personalDataFormFieldsMaxLength } from '../types';
import { clearPhoneNumberCharacters } from '../../../common/utils/clearString';

const firstNameInputProps: InputBaseComponentProps = {
  maxLength: personalDataFormFieldsMaxLength.firstName
};

const lastNameInputProps: InputBaseComponentProps = {
  maxLength: personalDataFormFieldsMaxLength.firstName
};

const emailInputProps: Partial<InputProps> = {
  readOnly: true
};

export const PersonalDataForm = () => {
  const { t } = useTranslation();
  const isMobile = useMobileScreenSizeDetector();
  const classes = useStyle();

  return (
    <form>
      <Grid container spacing={isMobile ? 4 : 8} className={classes.gridContainer}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="firstName"
            label={t('personalDataPage.firstName.label')}
            placeholder={t('personalDataPage.firstName.placeholder')}
            inputProps={firstNameInputProps}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="lastName"
            label={t('personalDataPage.lastName.label')}
            placeholder={t('personalDataPage.lastName.placeholder')}
            inputProps={lastNameInputProps}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label={t('personalDataPage.phone.label')}
            name="phone"
            clearCharacters={clearPhoneNumberCharacters}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField InputProps={emailInputProps} name="email" label={t('personalDataPage.email.label')} />
        </Grid>
      </Grid>
    </form>
  );
};

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem'
    }
  }
}));
