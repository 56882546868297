import { makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { useUser } from 'features/user';
import { TFuncKey, Trans, useTranslation } from 'react-i18next';
import { Address } from '../types';
import { formatAddress } from '../utils';
import { country } from 'config';
import { ReactComponent as IconDay } from 'common/icons/icon-day.svg';
import { ReactComponent as IconNight } from 'common/icons/icon-night.svg';
import { DELIVERY_TYPE_STANDARD, DELIVERY_TYPE_EVENING } from '../constants/deliveryTypes';
import { InfoTooltipArrow } from 'common/components';
import { FunctionComponent } from 'react';

interface AddressSummaryProps {
  data: Address;
  includeDeliveryHours?: boolean;
  includeName?: boolean;
}

export const AddressSummary = ({
  data: {
    buildingNr,
    placeNr,
    street,
    postCode,
    city,
    deliveryHourTo,
    deliveryHourFrom,
    deliveryTypeKey,
    deliveryWeekendHourTo,
    deliveryWeekendHourFrom,
    deliveryWeekendTypeKey,
    companyName,
    intercomName,
    name
  },
  includeDeliveryHours = true,
  includeName = false
}: AddressSummaryProps) => {
  const {
    profile: { firstName, lastName }
  } = useUser();
  const { t } = useTranslation();
  const classes = useStyles();
  const showDeliveryHours = includeDeliveryHours && (deliveryHourTo || deliveryWeekendHourTo);

  function deliveryIcon(key?: string | null) {
    if (!key) {
      return <></>;
    }

    let icon: FunctionComponent | undefined = undefined;
    if (key === DELIVERY_TYPE_EVENING) {
      icon = IconNight;
    } else if (key === DELIVERY_TYPE_STANDARD) {
      icon = IconDay;
    }

    return (
      icon && (
        <InfoTooltipArrow
          className={classes.icon}
          name={`addresses.addressSummary.type.${key}`}
          title={<Trans i18nKey={`addresses.addressSummary.type.${key}` as TFuncKey} />}
          icon={icon}
        />
      )
    );
  }

  return (
    <>
      {includeName && (
        <Typography className={classes.name} noWrap>
          {name}
        </Typography>
      )}
      <Typography noWrap>{`${firstName} ${lastName}`}</Typography>
      <Typography noWrap>{formatAddress({ buildingNr, placeNr, street })}</Typography>
      <Typography noWrap>{`${postCode} ${city}`}</Typography>
      <Typography noWrap>{companyName}</Typography>
      <Typography noWrap>{intercomName}</Typography>
      {showDeliveryHours && (
        <>
          <Typography className={classes.deliveryHours}>{t('addresses.addressSummary.deliveryHours')}</Typography>
          {country !== 'czech' && deliveryHourTo && (
            <Typography noWrap>
              {deliveryHourFrom
                ? t('addresses.addressSummary.deliverySinceTo', {
                    timeFrom: deliveryHourFrom,
                    timeTo: deliveryHourTo
                  })
                : t('addresses.addressSummary.deliveryTo', { time: deliveryHourTo })}
            </Typography>
          )}
          {country === 'czech' && deliveryHourTo && (
            <Typography noWrap>
              {deliveryHourFrom
                ? t('addresses.addressSummary.deliveryHourSinceTo', {
                    timeFrom: deliveryHourFrom,
                    timeTo: deliveryHourTo
                  })
                : t('addresses.addressSummary.deliveryHourTo', { time: deliveryHourTo })}
              {deliveryIcon(deliveryTypeKey)}
            </Typography>
          )}
          {country === 'czech' && deliveryWeekendHourTo && (
            <Typography noWrap>
              {deliveryWeekendHourFrom
                ? t('addresses.addressSummary.deliveryWeekendHourSinceTo', {
                    timeFrom: deliveryWeekendHourFrom,
                    timeTo: deliveryWeekendHourTo
                  })
                : t('addresses.addressSummary.deliveryWeekendHourTo', { time: deliveryWeekendHourTo })}
              {deliveryIcon(deliveryWeekendTypeKey)}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  name: {
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  deliveryHours: {
    fontWeight: 'bold',
    marginBottom: '8px',
    marginTop: '12px'
  },
  icon: {
    marginLeft: '5px',
    marginBottom: '-2px'
  }
}));
