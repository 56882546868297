interface FormatAddressArgs {
  buildingNr: string;
  placeNr: string;
  street: string;
  postCode?: string;
  city?: string;
}

export const formatAddress = (
  { buildingNr, placeNr, street, postCode, city }: FormatAddressArgs,
  format: 'default' | 'parenthesis' = 'default'
) => {
  const formattedAddress = [buildingNr || '', placeNr || ''].filter((v) => v.length > 0).join('/');

  if (postCode && city && format === 'parenthesis') {
    return `${street} ${formattedAddress} (${postCode} ${city})`;
  } else if (postCode && city) {
    return `${street} ${formattedAddress}, ${postCode} ${city}`;
  } else {
    return `${street} ${formattedAddress}`;
  }
};
