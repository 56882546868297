import { z } from 'zod';
import { emailRegex } from 'common/utils/emailRegexp';

export const personalDataFormFieldsMaxLength = {
  firstName: 255,
  lastName: 255
};

const phoneNumberRegex = /^\+\d{6,20}$/;

export const personalDataSchema = z.object({
  firstName: z
    .string()
    .transform((input) => input.trim())
    .superRefine((value, ctx) => {
      const fieldValidationResult = z
        .string()
        .min(1, 'errors.required')
        .min(2, 'personalDataPage.firstName.validation.min')
        .max(personalDataFormFieldsMaxLength.firstName, 'personalDataPage.firstName.validation.max')
        .safeParse(value);
      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    }),
  lastName: z
    .string()
    .transform((input) => input.trim())
    .superRefine((value, ctx) => {
      const fieldValidationResult = z
        .string()
        .min(1, 'errors.required')
        .min(2, 'personalDataPage.firstName.validation.min')
        .max(personalDataFormFieldsMaxLength.lastName, 'personalDataPage.firstName.validation.max')
        .safeParse(value);
      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    }),
  email: z.string().regex(emailRegex, 'errors.incorrectEmail'),
  phone: z
    .string()
    .max(21, 'personalDataPage.phone.validation.format')
    .regex(phoneNumberRegex, 'personalDataPage.phone.validation.format')
});

export type PersonalData = z.infer<typeof personalDataSchema>;
