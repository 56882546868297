import { Grid, makeStyles, Theme } from '@material-ui/core';
import { CalendarDate } from 'common/types';
import { CreateDietFormData } from 'features/orders';
import { useDietConfiguration } from 'features/orders/contexts';
import { getDietLenghtOptions } from 'features/orders/utils';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from 'common/components';
import { useAllowWeekends } from 'features/orders/hooks/useAllowWeekends';
import { country } from 'config';
import { ContinuousDeliveryDatesCalendar, CustomDeliveryDatesCalendar } from './DeliveryDatesCalendar';

import { DietLengthSection } from './DietLengthSection';
import { WeekendsSection } from './WeekendsSection';

export const DeliveryDatesSection = (): JSX.Element => {
  const { t } = useTranslation();
  const allowWeekends = useAllowWeekends();
  const classes = useStyle({ hidden: country === 'germany' });

  const {
    deliveryTimeConfig: { firstDeliveryAt }
  } = useDietConfiguration();

  const [visibleMonth, setVisibleMonth] = useState<CalendarDate>(firstDeliveryAt);

  const withoutWeekends = useWatch<CreateDietFormData, 'withoutWeekends'>({
    name: 'withoutWeekends'
  });

  const dietLengthId = useWatch<CreateDietFormData, 'dietLength'>({
    name: 'dietLength'
  });

  const selectedDietLengthOption = getDietLenghtOptions(withoutWeekends).find((o) => o.id === dietLengthId);
  const isCustomDietLenghtOption = selectedDietLengthOption?.type === 'custom';

  const showWithWeekendsSection = !(isCustomDietLenghtOption || !allowWeekends);

  const subtitle = t('creatDietPage.calendarSection.subtitle') || undefined;
  const footer = t('creatDietPage.calendarSection.footer') || undefined;

  return (
    <FormRow title={t('creatDietPage.calendarSection.title')} subtitle={subtitle}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={classes.hidableElement}>
          <DietLengthSection />
          {showWithWeekendsSection ? <WeekendsSection /> : null}
        </Grid>
        <Grid item xs={12} md={6}>
          {isCustomDietLenghtOption ? (
            <CustomDeliveryDatesCalendar currentVisibleMonth={visibleMonth} onMonthChange={setVisibleMonth} />
          ) : (
            <ContinuousDeliveryDatesCalendar currentVisibleMonth={visibleMonth} onMonthChange={setVisibleMonth} />
          )}
          {footer && <div className={classes.footer}>{footer}</div>}
        </Grid>
      </Grid>
    </FormRow>
  );
};

const useStyle = makeStyles<Theme, { hidden?: boolean }>((theme: Theme) => ({
  hidableElement: {
    display: ({ hidden }) => (hidden ? 'none' : 'block')
  },
  footer: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.customColors.white,
    marginTop: '1.5rem',
    padding: '0.5rem',
    borderRadius: `0.313rem`,
    fontSize: '1rem',
    lineHeight: 1.19,
    textAlign: 'center',
    boxShadow: theme.boxShadow.lightGrey,
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      lineHeight: 1.29,
      letterSpacing: '0.013rem'
    }
  }
}));
