import { z } from 'zod';
import { formatAddress } from '../utils';

export const addressSchema = z
  .object({
    id: z.number(),
    city: z.string(),
    deliveryHourTo: z.string().nullable(),
    deliveryHourFrom: z.string().nullable(),
    deliveryType: z.number().nullable(),
    deliveryTypeKey: z.string().nullable(),
    deliveryWeekendHourTo: z.string().nullable(),
    deliveryWeekendHourFrom: z.string().nullable(),
    deliveryWeekendType: z.number().nullable(),
    deliveryWeekendTypeKey: z.string().nullable(),
    postCode: z.string(),
    street: z.string(),
    buildingNr: z.string(),
    placeNr: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    floorNr: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    companyName: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    intercomName: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    district: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    gateKey: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    intercomKey: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    packageLeavingOption: z.object({
      id: z.number(),
      value: z.string(),
      systemValue: z.string().nullable()
    }),
    otherPackageLeavingOption: z
      .string()
      .nullable()
      .transform((val) => val ?? ''),
    isSaturdayByDefault: z.boolean(),
    isStandardByDefault: z.boolean(),
    name: z.string().nullable(),
    underTheDoorType: z.string().nullable()
  })
  .transform((data) => ({
    ...data,
    name: data.name ?? formatAddress(data)
  }));
export type Address = z.output<typeof addressSchema>;
