import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { country } from 'config';
import { formatPrice } from './formatPrice';
import { formatDate } from './formatDate';
import { useI18nDayjs } from './i18n-dayjs';

const langVersion = '1.7.5.3';

const getLanguagePrefix = () => {
  switch (country) {
    case 'germany':
      return 'de';
    case 'poland':
      return 'pl';
    case 'czech':
      return 'cs';
    default:
      throw new Error('Country not supported');
  }
};
const lng = getLanguagePrefix();
i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng,
    fallbackLng: lng,
    load: 'languageOnly',
    backend: {
      loadPath: `/locales/{{lng}}.json?v=${langVersion}`
    },
    interpolation: {
      format(value, format, language) {
        if (format === 'currency') {
          return formatPrice(value, language ?? i18n.language);
        }

        if (format === 'date') {
          return formatDate(value, language ?? i18n.language);
        }

        return value;
      }
    }
  })
  .then(() => {
    useI18nDayjs(i18n.language);
  });

export default i18n;
