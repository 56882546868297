import { Divider, makeStyles, Typography } from '@material-ui/core';
import { ButtonLink, InfoTooltipArrow, InfoTooltipMobile } from 'common/components';
import { Trans, useTranslation } from 'react-i18next';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { useProfiles } from '../../contexts';
import { ProfileSelect } from './ProfileSelect';
import { ReactComponent as IconInfoAlt } from 'common/icons/icon-info-alt.svg';
import { ReactComponent as IconInfoAltWhite } from 'common/icons/icon-info-alt-white.svg';
import { country } from 'config/constants/country';
import { useMobileScreenSizeDetector } from 'common/hooks';

export const ProfileManagment = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const isMobile = useMobileScreenSizeDetector();

  const { profiles, setProfile, selectedProfileId } = useProfiles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.cardTitle} variant="h4">
        {t('profile.managment.header')}
        {['poland', 'czech'].includes(country) && (
          <span className={classes.tooltip}>
            {isMobile ? (
              <InfoTooltipMobile
                key="profile.managment.tooltip"
                title={<Trans i18nKey="profile.managment.tooltip" components={{ br: <br /> }} />}
                icon={IconInfoAltWhite}
              />
            ) : (
              <InfoTooltipArrow
                name="profile.managment.tooltip"
                title={<Trans i18nKey="profile.managment.tooltip" components={{ br: <br /> }} />}
                icon={IconInfoAlt}
              />
            )}
          </span>
        )}
      </Typography>
      <ProfileSelect items={profiles} selected={selectedProfileId} onChange={setProfile} />
      <ButtonLink className={classes.addProfile} mode="secondary" fullWidth to={RoutesDefinition.createProfile}>
        {t('profile.managment.add')}
      </ButtonLink>

      <Divider className={classes.divider} />
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& div': {
        boxShadow: 'none'
      }
    }
  },
  cardTitle: {
    marginBottom: '1rem'
  },
  addProfile: {
    marginTop: '1rem'
  },
  divider: {
    margin: `1.5rem 0`
  },
  tooltip: {
    marginLeft: '0.5rem'
  }
}));
