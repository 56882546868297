import { useController, useFormContext } from 'react-hook-form';
import { FormEvent } from 'react';
import { FormErrorMessage } from '../FormErrorMessage';
import { TextField, TextFieldProps } from '../TextField';
import { clearString } from '../../utils/clearString';

export interface FormTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'inputRef' | 'value' | 'error'> {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  defaultValue?: string;
  clearCharacters?: string[];
}

export const FormTextField = ({
  name,
  fullWidth = true,
  defaultValue,
  multiline,
  clearCharacters,
  ...rest
}: FormTextFieldProps): JSX.Element => {
  const {
    formState: { errors }
  } = useFormContext();

  const {
    field: { ref, value, ...fieldProps },
    fieldState: { error }
  } = useController({
    name,
    defaultValue
  });
  let { inputProps } = rest;
  inputProps = inputProps || {};
  inputProps.onInput = (event: FormEvent<HTMLInputElement>): void => {
    const { target } = event;

    if (target instanceof HTMLInputElement && target.value && clearCharacters) {
      target.value = clearString(target.value, clearCharacters) as string;
    }

    if (
      multiline &&
      inputProps?.maxLength &&
      target instanceof HTMLTextAreaElement &&
      target.value &&
      target.value.length > inputProps.maxLength
    ) {
      target.value = target.value.slice(0, inputProps.maxLength);
    }
  };

  return (
    <TextField
      {...rest}
      {...fieldProps}
      id={name}
      inputProps={inputProps}
      inputRef={ref}
      error={!!error}
      fullWidth={fullWidth}
      multiline={multiline}
      helperText={<FormErrorMessage errors={errors} name={name} />}
    />
  );
};
