import { Time, transformTimeToDayjs } from 'common/types';
import dayjs from 'dayjs';
import { TIME_FORMAT } from 'common/constants';
import { AddressDeliveryDataResponse, AddressDeliveryTime, AddressDeliveryTimeResponse } from './types';

type DeliveryTimeOption = {
  timeFrom?: Time;
  timeTo: Time;
  isWeekend: boolean;
  deliveryTypeKey: string;
  deliveryTypeId: number;
};

export const mapDeliveryTimeToOptions = (addressDeliveryData?: AddressDeliveryDataResponse): DeliveryTimeOption[] => {
  if (!addressDeliveryData) {
    return [];
  }

  const options: DeliveryTimeOption[] = addressDeliveryData.showSinceToOption
    ? mapDeliveryShowSinceTo(addressDeliveryData.allDeliveryTimes)
    : mapDeliveryShowToOnly(addressDeliveryData.allDeliveryTimes[0]);

  return options;
};

const mapDeliveryShowSinceTo = (deliveryTimes: AddressDeliveryTime[]): DeliveryTimeOption[] => {
  const options: DeliveryTimeOption[] = deliveryTimes
    .sort((a, b) => (transformTimeToDayjs(a.timeTo).isBefore(transformTimeToDayjs(b.timeTo)) ? -1 : 1))
    .map(({ timeFrom, timeTo, isWeekend, deliveryTypeKey, deliveryTypeId }) => ({
      timeFrom,
      timeTo,
      isWeekend,
      deliveryTypeKey,
      deliveryTypeId
    }));

  return options;
};

const mapDeliveryShowToOnly = ({
  timeTo,
  timeFrom,
  interval,
  step,
  isWeekend,
  deliveryTypeId,
  deliveryTypeKey
}: AddressDeliveryTimeResponse): DeliveryTimeOption[] => {
  const options: DeliveryTimeOption[] = [];
  if (timeFrom) {
    const parsedTimeFrom = transformTimeToDayjs(timeFrom);
    const parsedInterval = interval.match(/(\d{2}):(\d{2})/);
    const parsedStep = step.match(/(\d{2}):(\d{2})/);

    if (parsedInterval === null || parsedStep === null) {
      return options;
    }
    const [, intervalHours, intervalMinutes] = parsedInterval;
    const [, stepHours, stepMinutes] = parsedStep;

    const intervalDuration = dayjs.duration({
      hours: Number(intervalHours),
      minutes: Number(intervalMinutes)
    });

    const stepDuration = dayjs.duration({
      hours: Number(stepHours),
      minutes: Number(stepMinutes)
    });

    let current = parsedTimeFrom.add(intervalDuration);

    const end = transformTimeToDayjs(timeTo);

    while (current.isSameOrBefore(end, 'minute')) {
      options.push({
        timeTo: current.format(TIME_FORMAT),
        isWeekend,
        deliveryTypeId,
        deliveryTypeKey
      });
      current = current.add(stepDuration);
    }
  }
  return options;
};
