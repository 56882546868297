import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useUser } from 'features/user';
import { AccountLayout } from 'layouts';
import {
  PersonalData,
  personalDataFormFieldsMaxLength,
  personalDataSchema
} from 'features/orders/types/personalDataForm';
import { Button } from 'common/components/Button';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { currentCountryPrefix } from 'config/constants/defaultPhoneNumberPrefix';
import { FormTextField } from 'common/components';
import { useEffect } from 'react';
import { useDisclosure } from 'common/hooks/useDisclosure';
import { useMutation } from 'react-query';
import { updatePersonalData } from 'features/orders';
import { HTTPError } from 'ky';
import { useSnackbar } from 'notistack';
import { useApi } from 'features/apiProvider';
import { ChangeUserDataConfirmationModal } from './components/ChangeUserDataConfirmationModal';
import { SuccessChangeDataModal } from './components/SuccessChangeDataModal';
import { clearPhoneNumberCharacters } from '../../common/utils/clearString';

export const UserDataPage = (): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  const {
    profile: { email, firstName, lastName, phone, isLoading, refetch }
  } = useUser();
  const modal = useDisclosure();
  const successModal = useDisclosure();
  const { getApiClient } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading: isPending } = useMutation({
    mutationFn: updatePersonalData,
    onSuccess: async () => {
      await refetch();
      modal.close();
      successModal.open();
    },
    onError: async (err: HTTPError) => {
      const errorData: { messages?: string[] } = await err.response.json();
      modal.close();

      const message = errorData.messages?.[0] || t('personalDataPage.formSubmitError');

      enqueueSnackbar(message, {
        variant: 'error'
      });
    }
  });

  const personalDataForm = useForm<PersonalData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(personalDataSchema),
    defaultValues: {
      firstName,
      email,
      lastName,
      phone: phone.length > 0 ? phone : currentCountryPrefix
    }
  });

  useEffect(() => {
    if (!isLoading) {
      personalDataForm.setValue('firstName', firstName);
      personalDataForm.setValue('lastName', lastName);
      personalDataForm.setValue('phone', phone.length > 0 ? phone : currentCountryPrefix);
      personalDataForm.setValue('email', email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const checkHasChanges = () => {
    const values = personalDataForm.watch();
    return values.firstName === firstName && values.lastName === lastName && values.phone === phone;
  };

  const handleOpenModal: SubmitHandler<PersonalData> = () => {
    modal.open();
  };

  const handleSubmit: SubmitHandler<PersonalData> = (data) => {
    mutate({ apiClient: getApiClient(), data });
  };

  return (
    <AccountLayout title={t('userDataPage.title')} isLoading={isLoading}>
      <FormProvider {...personalDataForm}>
        <form className={classes.content}>
          <FormTextField
            name="firstName"
            label={t('personalDataPage.firstName.label')}
            placeholder={t('personalDataPage.firstName.placeholder')}
            inputProps={{ maxLength: personalDataFormFieldsMaxLength.firstName }}
          />
          <FormTextField
            name="lastName"
            label={t('personalDataPage.lastName.label')}
            placeholder={t('personalDataPage.lastName.placeholder')}
            inputProps={{ maxLength: personalDataFormFieldsMaxLength.lastName }}
          />
          <FormTextField
            label={t('personalDataPage.phone.label')}
            name="phone"
            clearCharacters={clearPhoneNumberCharacters}
          />
          <div className={classes.container}>
            <FormTextField InputProps={{ readOnly: true }} name="email" label={t('personalDataPage.email.label')} />
            <span className={classes.info}>{t('personalDataPage.email.unchangeable')}</span>
          </div>
          <Button
            mode="primary"
            onClick={personalDataForm.handleSubmit(handleOpenModal)}
            disabled={isLoading || checkHasChanges()}
          >
            {t('personalDataPage.save')}
          </Button>
        </form>
      </FormProvider>
      <ChangeUserDataConfirmationModal
        isPending={isPending || isLoading}
        isOpen={modal.isOpen}
        onClose={modal.close}
        onConfirm={personalDataForm.handleSubmit(handleSubmit)}
      />
      <SuccessChangeDataModal isOpen={successModal.isOpen} onClose={successModal.close} />
    </AccountLayout>
  );
};

const useStyle = makeStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem'
  },
  info: {
    color: theme.customColors.dimGrey,
    fontSize: '0.75rem'
  }
}));
